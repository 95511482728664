import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './Component/Home';
import config from "./config/config";

function App() {
  return (
    <>
   <Router>
      <Switch>
      <Route path={`${config.baseUrl}`} exact component={Home} />
       
      </Switch>
    </Router>
    </>
  );
}
  
export default App;
