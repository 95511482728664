const config = {

    //=========================  Localhost ========================

    baseUrl: '/',
    // apiUrl: "http://localhost:9000/api/",
    // imageUrl: "https://espsofttech.tech/abc-wallet/backend/uploads/",
    // loaderType:"Ball-Triangle"

    //=========================  Esp server ========================

    // baseUrl: '/abc-wallet/',
    // apiUrl: "https://espsofttech.tech:6017/api/",
    // imageUrl:"https://espsofttech.tech/abc-wallet/backend/uploads/", 
    // loaderType:"Ball-Triangle"

    //=========================  Live server ========================

    // baseUrl: '/',
    // apiUrl: "http://18.217.124.116:9000/api/",
    // imageUrl: "http://18.217.124.116/",
    // loaderType:"Ball-Triangle"

};
export default config;

