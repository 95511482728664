import React from 'react';

const Loader = () => {
  return <div>
        <div id="preloader">
         <div class="preloader-wrapper">
            <div class="spinner"></div>
         </div>
         <div class="preloader-cancel-btn">
            <a href="#" class="btn btn-secondary prelaoder-btn">Cancel Preloader</a>
         </div>
      </div>
      
  </div>;
};

export default Loader;
