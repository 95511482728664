import React from "react";

const Header = () => {
  const loadingData = () => {
    setTimeout(() => {
      window.location.reload();
    });
  };
  return (
    <div>
      <div className="header-transparent nav-sticky">
        {/*    <div class="xs-top-bar">
            <div class="container">
               <div class="row">
                  <div class="col-md-6">
                     <ul class="xs-top-bar-info">
                        <li>
                           <p><i class="icon icon-phone1"></i>009-215-5596</p>
                        </li>
                        <li>
                           <a href="/cdn-cgi/l/email-protection#325b5c545d72565d5f535b5c1c515d5f"><i class="icon icon-envelope1"></i><span class="__cf_email__" data-cfemail="d5bcbbb3ba95b1bab8b4bcbbfbb6bab8">[email&#160;protected]</span></a>
                        </li>
                     </ul>
                  </div>
                  <div class="col-md-6">
                     <ul class="top-menu">
                        <li><a href="login.html"><i class="icon icon-key2"></i> Login</a></li>
                        <li><a href="support.html"><i class="icon icon-hours-support"></i> Support</a></li>
                     </ul>
                  </div>
               </div>
            </div>
         </div> */}
        <header className="xs-header header-boxed">
          <div className="container">
            <div className="row mainheader">
              <div className="col-lg-2">
                <div className="xs-logo-wraper">
                  {/* <a href="index.html" className="xs-logo"> */}
                  <a href="#" className="xs-logo">
                    <img
                      src="images/color-logo.png"
                      alt=""
                      className="main-logo"
                    />
                    <img
                      src="images/white-color-logo.png"
                      alt=""
                      className="sticky-logo"
                    />
                    <span className="company_name">FantomDomains.io</span>
                  </a>
                </div>
              </div>
              <div className="col-lg-8">
                <nav className="xs-menus">
                  <div className="nav-header">
                    {/* <a className="nav-brand" href="index.html" /> */}
                    <a className="nav-brand" href="#" />
                    <div className="nav-toggle" />
                  </div>
                  <div className="nav-menus-wrapper">
                    <ul className="nav-menu align-to-right xs-menu-tools">
                      <li>
                        {/* <a href="https://fantomdomains.io/reservation">Reservation</a> */}
                        {/*  <div class="megamenu-panel home-menu-panel">
                                    <div class="row">
                                       <div class="col-lg-4">
                                          <a href="index.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-one.jpg" alt="">
                                             <h4>Home One</h4>
                                          </a>
                                       </div>
                                       <div class="col-lg-4">
                                          <a href="index-v2.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-two.jpg" alt="">
                                             <h4>Home Two</h4>
                                          </a>
                                       </div>
                                       <div class="col-lg-4">
                                          <a href="index-v3.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-three.jpg" alt="">
                                             <h4>Home Three</h4>
                                          </a>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-lg-4">
                                          <a href="index-v4.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-four.jpg" alt="">
                                             <h4>Home Four</h4>
                                          </a>
                                       </div>
                                       <div class="col-lg-4">
                                          <a href="index-v5.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-five.jpg" alt="">
                                             <h4>Home Five</h4>
                                          </a>
                                       </div>
                                       <div class="col-lg-4">
                                          <a href="index-v6.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-six.jpg" alt="">
                                             <h4>Home Six</h4>
                                          </a>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-lg-4">
                                          <a href="index-v7.html" class="single-menu-panle" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-seven.jpg" alt="">
                                             <span class="menu-highlight-badge">NEW</span>
                                             <h4>Home Seven</h4>
                                          </a>
                                       </div>
                                       <div class="col-lg-4">
                                          <a href="index-v8.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-seven.jpg" alt="">
                                             <span class="menu-highlight-badge">NEW</span>
                                             <h4>Home Eight Rev Hero</h4>
                                          </a>
                                       </div>
                                       <div class="col-lg-4">
                                          <a href="index-v9.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-nine.jpg" alt="">
                                             <span class="menu-highlight-badge">NEW</span>
                                             <h4>Home Nine</h4>
                                          </a>
                                       </div>
                                    </div>
                                    <div class="row">
                                       <div class="col-lg-4">
                                          <a href="index-v10.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-ten.jpg" alt="">
                                             <span class="menu-highlight-badge">NEW</span>
                                             <h4>Home Ten</h4>
                                          </a>
                                       </div>
                                       <div class="col-lg-4">
                                          <a href="index-v11.html" class="single-menu-panle">
                                             <img src="assets/images/menu-screenshorts/home-eleven.jpg" alt="">
                                             <span class="menu-highlight-badge">NEW</span>
                                             <h4>Home Eleven Rev Slider</h4>
                                          </a>
                                       </div>
                                    </div>
                                    </div> */}
                      </li>
                      <li>
                        {/* <a href="https://espsofttech.tech/fantom_NFT/"  >Auction</a> */}

                        <a style={{ color: "#808080" }}>Auction</a>
                        {/* <a href="https://espsofttech.tech/fantom/">Auction</a> */}
                        {/*  <div class="megamenu-panel xs-icon-menu">
                                    <div class="row">
                                       <div class="col-lg-4">
                                          <div class="single-menu-item">
                                             <a href="about.html"><i class="icon icon-briefcase"></i>About</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="affiliate.html"><i class="icon icon-line-chart"></i>Affiliate</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="pricing.html"><i class="icon icon-agenda"></i>Pricing</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="faq.html"><i class="icon icon-question-circle"></i>FAQ</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="terms.html"><i class="icon icon-direction"></i>Terms</a>
                                          </div>
                                       </div>
                                       <div class="col-lg-4">
                                          <div class="single-menu-item">
                                             <a href="who-is.html"><i class="icon icon-folder"></i>Whois</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="support.html"><i class="icon icon-chat"></i>Support</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="cart.html"><i class="icon icon-shopping-cart-1"></i>Cart</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="checkout.html"><i class="icon icon-wallet"></i>Checkout</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="privacy.html"><i class="icon icon-padlock-1"></i>Privacy</a>
                                          </div>
                                       </div>
                                       <div class="col-lg-4">
                                          <div class="single-menu-item">
                                             <a href="login.html"><i class="icon icon-agenda-1"></i>Login</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="signup.html"><i class="icon icon-users"></i> Signup</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="forgot-password.html"><i class="icon icon-safebox"></i> Forgot password</a>
                                          </div>
                                          <div class="single-menu-item">
                                             <a href="404.html"><i class="icon icon-sad"></i>404</a>
                                          </div>
                                       </div>
                                    </div>
                                    </div> */}
                      </li>

                      <li>
                        <a href="mailto:contact@fantomdomains.io">Contact</a>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="languageSwitcher-button xs-modal-popup"
                        >
                          <i className="icon icon-internet" />
                        </a>
                        {/* <a href="#modal-popup-1" className="languageSwitcher-button xs-modal-popup"><i className="icon icon-internet" /></a> */}
                      </li>
                      <li>
                        <a
                          href="https://app.fantomdomains.io"
                          className="btn btn-primary go_to"
                          id="go-to-app-btn"
                        >
                          Go to app
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
