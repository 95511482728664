import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import Loader from "./Loader";
const Home = () => {
  return (
    <div>
      <Loader />
      <Header />
      <section className="xs-banner banner-v4 banner-v5 contet-to-center curve-bg">
        <div className="container">
          <div className="row">
            <div className="col-md-8 mx-auto align-self-center">
              <div className="xs-banner-content">
                <h2 className="banner-title wow fadeInUp">
                  Decentralised naming for wallet, website, &amp; more
                </h2>
                {/*    <ul class="domain-box-list wow fadeInUp" data-wow-duration="1.3s">
                        <li>
                           .com
                           <hr>
                           $10.99
                        </li>
                        <li>
                           .net
                           <hr>
                           $1.46 
                        </li>
                        <li>
                           .io
                           <hr>
                           $5.50 
                        </li>
                        <li>
                           .co
                           <hr>
                           $8.00
                        </li>
                        </ul> */}
              </div>
            </div>
          </div>
          <div className="card_feature">
            <div className="row">
              {/* <div class="col-lg-3">
                     <div class="xs-feature-group wow fadeInLeft animated" style="visibility: visible; animation-name: fadeInLeft;">
                        <div class="feature-img">
                           <img src="images/included-feature/included-feature-1.png" alt="hosting feature img" draggable="false">
                        </div>
                        <div class="media">
                           <div class="media-body feature-content">
                              <h4 class="xs-title">Add Web Hosting from us</h4>
                              <p>Choose from a range web hosting packages .</p>
                              <div class="xs-btn-wraper">
                                 <a href="#" class="btn btn-secondary">Buy Now</a>
                                 </div>
                           </div>
                        </div>
                        <span class="icon icon-hosting watermark-icon"></span>
                     </div>
                  </div> */}
              <div className="col-lg-4 mb-3">
                <div
                  className="xs-feature-group wow fadeInRight animated"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInRight",
                  }}
                >
                  <div className="feature-img">
                    <img
                      src="images/included-feature/loupe.png"
                      alt="hosting feature img"
                      draggable="false"
                    />
                  </div>
                  <div className="media">
                    <div className="media-body feature-content">
                      <h4 className="xs-title">Search</h4>
                      <p>Search for available domains.</p>
                      {/* <p>Transfer now to extend your domain by 1 year! Its so easy.</p> */}
                      {/* <div class="xs-btn-wraper">
                                 <a href="#" class="btn btn-secondary">Get Started</a>
                                 </div> */}
                    </div>
                  </div>
                  <span className="icon icon-domian watermark-icon" />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div
                  className="xs-feature-group wow fadeInRight animated"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInRight",
                  }}
                >
                  <div className="feature-img">
                    <img
                      src="images/included-feature/note.png"
                      alt="hosting feature img"
                      draggable="false"
                    />
                  </div>
                  <div className="media">
                    <div className="media-body feature-content">
                      <h4 className="xs-title">Register</h4>
                      <p>Register a domain name</p>
                      {/* <p>Transfer now to extend your domain by 1 year! Its so easy.</p> */}
                      {/* <div class="xs-btn-wraper">
                                 <a href="#" class="btn btn-secondary">Get Started</a>
                                 </div> */}
                    </div>
                  </div>
                  <span className="icon icon-domian watermark-icon" />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div
                  className="xs-feature-group wow fadeInRight animated"
                  style={{
                    visibility: "visible",
                    animationName: "fadeInRight",
                  }}
                >
                  <div className="feature-img">
                    <img
                      src="images/included-feature/data-management.png"
                      alt="hosting feature img"
                      draggable="false"
                    />
                  </div>
                  <div className="media">
                    <div className="media-body feature-content">
                      <h4 className="xs-title">Manage</h4>
                      <p>
                        Transfer ownership, renew/extend domain expiration date,
                        create/manage subdomains
                      </p>
                      {/* <div class="xs-btn-wraper">
                                 <a href="#" class="btn btn-secondary">Get Started</a>
                                 </div> */}
                    </div>
                  </div>
                  <span className="icon icon-domian watermark-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="wave_animation_wraper">
          <div className="wave_animation">
            <div className="layer wave one" data-depth="0.20">
              <img
                src="images/welcome/horizontal-shape.png"
                alt="horizontal shape"
              />
            </div>
            <div className="layer wave two" data-depth="0.6">
              <img
                src="images/welcome/vertical-shape-1.png"
                alt="vrtical shape"
              />
            </div>
            <div className="layer wave three" data-depth="0.4">
              <img
                src="images/welcome/vertical-shape-2.png"
                alt="vrtical shape"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="4_cards">
        <div className="container"></div>
      </section>
      <section className="xs-section-padding xs-hosting-info">
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-6 mx-auto">
              <div className="xs-heading wow fadeIn">
                <h3 className="heading-title">
                  {" "}
                  <span>Your web3 Username</span>
                </h3>
                <h2 className="heading-sub-title">Best Domain</h2>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className="hosting-info-img">
                <lottie-player
                  src="https://assets6.lottiefiles.com/packages/lf20_3jezq8s4.json"
                  background="transparent"
                  speed={1}
                  style={{ width: "100%", height: "100%" }}
                  loop
                  autoPlay
                />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="hosting-info-wraper">
                {/*  <div class="spinner-icon wow animated" style="visibility: visible;">
                        <img src="assets/images/included-feature/web-link.png" alt="hosting info info" draggable="false">
                     </div> */}
                <div
                  className="wow fadeInUp animated"
                  style={{ visibility: "visible", animationName: "fadeInUp" }}
                >
                  <h2 className="content-title">
                    One Name For All of Your Addresses
                  </h2>
                  <p>
                    No more copying and pasting long addresses. Use your FNS
                    name to store all of your addresses and receive any
                    cryptocurrency, token, or NFT.
                  </p>
                  <ul className="xs-list check">
                    <li>No more copying and pasting long addresses</li>
                    {/* <li>No more copying and pasting long addresses.</li>
                                        <li>No more copying and pasting long addresses</li> */}
                  </ul>
                  <a href="#" className="btn btn-primary">
                    Hyperlink to app page
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row">
                        <div className="col-lg-6 align-self-center">
                            <div className="hosting-info-wraper-2 wow fadeInLeft animated" data-wow-duration="1.5s" style={{ visibility: 'visible', animationDuration: '1.5s', animationName: 'fadeInLeft' }}>
                                <h2 className="content-title">Decentralised Websites</h2>
                                <p>Launch censorship-resistant decentralised websites with FNS. Upload your website to IPFS and access it with your FNS name.</p>
                                <div className="media hosting-info-list">
                                    <div className="d-flex info-icon">
                                        <img src="images/gaurd-icon.png" alt="hosting info icon" draggable="false" />
                                    </div>
                                    <div className="media-body">
                                        <h4 className="xs-title small">Better Security and faster Server</h4>
                                        <p>Upload your website to IPFS and access it with your FNS name.</p>
                                    </div>
                                </div>
                                <div className="media hosting-info-list">
                                    <div className="d-flex info-icon">
                                        <img src="images/cloud-icon.png" alt="hosting info icon" draggable="false" />
                                    </div>
                                    <div className="media-body">
                                        <h4 className="xs-title small">No-Downtime website transfers </h4>
                                        <p>Upload your website to IPFS and access it with your FNS name.</p>
                                    </div>
                                </div>
                                <div className="media hosting-info-list">
                                    <div className="d-flex info-icon">
                                        <img src="images/clock-icon.png" alt="hosting info icon" draggable="false" />
                                    </div>
                                    <div className="media-body">
                                        <h4 className="xs-title small">Domain within few minutes </h4>
                                        <p>Upload your website to IPFS and access it with your FNS name.</p>
                                    </div>
                                </div>
                                <a href="#" className="btn btn-primary">Get Started</a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="hosting-info-img wow fadeInRight animated" data-wow-duration="2s" style={{ visibility: 'visible', animationDuration: '2s', animationName: 'fadeInRight' }}>
                                <lottie-player src="https://assets1.lottiefiles.com/packages/lf20_hd63kfgz.json" background="transparent" speed={1} style={{ width: '100%', height: '100%' }} loop autoPlay />
                            </div>
                        </div>
                    </div> */}
        </div>
      </section>

      <section
        className="xs-testimonial-section testimonial-v5 wow fadeIn animated"
        style={{ visibility: "visible", animationName: "fadeIn" }}
      >
        <div className="xs-feature-section">
          <div className="container">
            <div className="xs-heading wow fadeIn mb-5">
              <h3 className="heading-title">
                Get <span>Involved</span>
              </h3>
              {/* <h2 class="heading-sub-title">Best Wallet</h2> */}
            </div>
            <div className="row">
              <div className="col-lg-4 mb-3">
                <div className="xs-feature-group wow fadeInLeft">
                  <div className="media d-block">
                    <div className="feature-img">
                      <img
                        src="images/chooseUs/choose-us-2.png"
                        alt="hosting feature img"
                      />
                    </div>
                    <div className="media-body feature-content">
                      <h4 className="xs-title">
                        Subscribe to our mailing list
                      </h4>
                      <p>
                        Own your username, store an avatar and other profile
                        data, and use it across services.
                      </p>
                      <div className="xs-btn-wraper">
                        <a href="#" className="btn btn-secondary">
                          Subscribe
                        </a>
                      </div>
                    </div>
                  </div>
                  <span className="icon icon-hosting watermark-icon" />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="xs-feature-group wow fadeInRight">
                  <div className="media d-block">
                    <div className="feature-img">
                      <img
                        src="images/feature-icon-2.png"
                        alt="hosting feature img"
                      />
                    </div>
                    <div className="media-body feature-content">
                      <h4 className="xs-title">Read our documentation</h4>
                      <p>
                        Own your username, store an avatar and other profile
                        data, and use it across services.
                      </p>
                      <div className="xs-btn-wraper">
                        <a href="#" className="btn btn-secondary">
                          Read
                        </a>
                      </div>
                    </div>
                  </div>
                  <span className="icon icon-domian watermark-icon" />
                </div>
              </div>
              <div className="col-lg-4 mb-3">
                <div className="xs-feature-group wow fadeInRight">
                  <div className="media d-block">
                    <div className="feature-img">
                      <img
                        src="images/chooseUs/choose-us-3.png"
                        alt="hosting feature img"
                      />
                    </div>
                    <div className="media-body feature-content">
                      <h4 className="xs-title">Join our Telegram community</h4>
                      <p>
                        Own your username, store an avatar and other profile
                        data, and use it across services.
                      </p>
                      <div className="xs-btn-wraper">
                        <a href="#" className="btn btn-secondary">
                          Join Telegram
                        </a>
                        {/* <a href="https://t.me/FantomDomains" className="btn btn-secondary">Join Telegram</a> */}
                      </div>
                    </div>
                  </div>
                  <span className="icon icon-domian watermark-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="xs-testimonial-section testimonial-v5 wow fadeIn">
        <div className="container">
          <div className="xs-heading wow fadeIn mb-5">
            <h3 className="heading-title">
              Fantom <span>Ecosystem</span>
            </h3>
            {/* <h2 class="heading-sub-title">Best Wallet</h2> */}
          </div>
          <div className="xs-testimonial-slider owl-carousel">
            {/* <div className="xs-testimonial-item">
              <div className="testimonial-content">
                <p>Launch censorship-resistant decentralised websites with FNS. Upload your website to IPFS and access it with your FNS name.</p>
                <div className="commentor-bio media">
                  <div className="d-flex round-avatar">
                    <img src="images/included-feature/eco1.png" alt="avatar image" />
                  </div>-----
                  <div className="media-body align-self-center">
                    <h4 className="commentor-title">Coinbase</h4>
                    <p className="commentor-info">Wallet</p>
                    <i className="icon icon-quote" />
                  </div>
                </div>
              </div>
            </div>
            <div className="xs-testimonial-item">
              <div className="testimonial-content">
                <p>Launch censorship-resistant decentralised websites with FNS. Upload your website to IPFS and access it with your FNS name.</p>
                <div className="commentor-bio media">
                  <div className="d-flex round-avatar">
                    <img src="images/included-feature/eco2.jpg" alt="avatar image" />
                  </div>-------
                  <div className="media-body align-self-center">
                    <h4 className="commentor-title">Rainbow</h4>
                    <p className="commentor-info">Wallet</p>
                    <i className="icon icon-quote" />
                  </div>
                </div>
              </div>
            </div>
            <div className="xs-testimonial-item">
              <div className="testimonial-content">
                <p>Launch censorship-resistant decentralised websites with FNS. Upload your website to IPFS and access it with your FNS name.</p>
                <div className="commentor-bio media">
                  <div className="d-flex round-avatar">
                    <img src="images//included-feature/eco3.png" alt="avatar image" />
                  </div>--------
                  <div className="media-body align-self-center">
                    <h4 className="commentor-title">Trust Wallet</h4>
                    <p className="commentor-info">Wallet</p>
                    <i className="icon icon-quote" />
                  </div>
                </div>
              </div>
            </div>
            <div className="xs-testimonial-item">
              <div className="testimonial-content">
                <p>Replace crypto addresses with an easily readable domain name to send &amp; receive crypto. Domain names for $20+. 1.5M+ domain names already registered</p>
                <div className="commentor-bio media">
                  <div className="d-flex round-avatar">
                    <img src="images//included-feature/eco4.jpg" alt="avatar image" />
                  </div>-----
                  <div className="media-body align-self-center">
                    <h4 className="commentor-title">MyCrypto</h4>
                    <p className="commentor-info">Wallet</p>
                    <i className="icon icon-quote" />
                  </div>
                </div>
              </div>
            </div>
            <div className="xs-testimonial-item">
              <div className="testimonial-content">
                <p>Launch censorship-resistant decentralised websites with FNS. Upload your website to IPFS and access it with your FNS name.</p>
                <div className="commentor-bio media">
                  <div className="d-flex round-avatar">
                    <img src="images//included-feature/eco5.jpg" alt="avatar image" />
                  </div>------
                  <div className="media-body align-self-center">
                    <h4 className="commentor-title">Bitcoin.com</h4>
                    <p className="commentor-info">Wallet</p>
                    <i className="icon icon-quote" />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="">
              <div className="d-flex ">
                <img
                  src="images/included-feature/eco1.png"
                  alt="avatar image"
                />
              </div>
              <div className="media-body">
                <h4 className="slides_name">Coinbase</h4>
              </div>
            </div>
            <div className="">
              <div className="d-flex ">
                <img
                  src="images//included-feature/eco3.png"
                  alt="avatar image"
                />
              </div>
              <div className="media-body">
                <h4 className="slides_name">Trust Wallet</h4>
              </div>
            </div>
            <div className="">
              <div className="d-flex ">
                <img
                  src="images//included-feature/metamask.jpg"
                  alt="avatar image"
                />
              </div>
              <div className="media-body">
                <h4 className="slides_name">MetaMask Extension</h4>
              </div>
            </div>
            <div className="text_logo">
              <div className="d-flex ">
                <img
                  src="images//included-feature/eco6.svg"
                  alt="avatar image"
                />
              </div>
              <div className="media-body">
                <h4 className="slides_name">FTMScan</h4>
              </div>
            </div>
            <div className="">
              <div className="d-flex ">
                <img
                  src="images//included-feature/eco7.png"
                  alt="avatar image"
                />
              </div>
              <div className="media-body">
                <h4 className="slides_name">SpiritSwap </h4>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="zoom-anim-dialog mfp-hide modal-language"
        id="modal-popup-1"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="language-content">
              <p>Switch The Language</p>
              <ul className="flag-lists">
                <li>
                  <a href="#">
                    <img src="images/flags/006-united-states.svg" alt="" />
                    <span>English</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/flags/002-canada.svg" alt="" />
                    <span>English</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/flags/003-vietnam.svg" alt="" />
                    <span>Vietnamese</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/flags/004-france.svg" alt="" />
                    <span>French</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/flags/005-germany.svg" alt="" />
                    <span>German</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div
        className="zoom-anim-dialog mfp-hide modal-searchPanel"
        id="modal-popup-2"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="xs-search-panel">
              <form action="#" method="POST" className="xs-search-group">
                <input
                  type="search"
                  className="form-control"
                  name="search"
                  id="search"
                  placeholder="Search"
                />
                <button type="submit" className="search-button">
                  <i className="icon icon-search" />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="xs-sidebar-group cart-group">
        <div className="xs-overlay xs-bg-black" />
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div className="widget-heading media">
              <div className="media-body">
                <a href="#" className="close-side-widget">
                  X
                </a>
              </div>
            </div>
            <div className="xs-empty-content">
              <h3 className="widget-title">Shopping cart</h3>
              <h4 className="xs-title">No products in the cart.</h4>
              <p className="empty-cart-icon">
                <i className="icon icon-shopping-cart" />
              </p>
              <p className="xs-btn-wraper">
                <a className="btn btn-primary" href="#">
                  Return To Shop
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="xs-sidebar-group info-group">
        <div className="xs-overlay xs-bg-black" />
        <div className="xs-sidebar-widget">
          <div className="sidebar-widget-container">
            <div className="widget-heading">
              <a href="#" className="close-side-widget">
                X
              </a>
            </div>
            <div className="sidebar-textwidget">
              <div className="sidebar-logo-wraper">
                <img src="images/blue_logo.png" alt="sidebar logo" />
              </div>
              <p>
                Far far away, behind the word moun tains, far from the countries
                Vokalia and Consonantia, there live the blind texts. Separated
                they live in Bookmarksgrove right at the coast of{" "}
              </p>
              <ul className="sideabr-list-widget">
                <li>
                  <div className="media">
                    <div className="d-flex">
                      <img src="images/location.png" alt="" />
                    </div>
                    <div className="media-body">
                      <p>759 Pinewood Avenue</p>
                      <span>Marquette, Michigan</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="media">
                    <div className="d-flex">
                      <img src="images/mail.png" alt="" />
                    </div>
                    <div className="media-body">
                      <a href="#">
                        <span
                          className="__cf_email__"
                          data-cfemail="10797e767f50747f7d71797e3e737f7d"
                        >
                          [email&nbsp;protected]
                        </span>
                      </a>
                      {/* <a href="/cdn-cgi/l/email-protection#b3daddd5dcf3d7dcded2dadd9dd0dcde"><span className="__cf_email__" data-cfemail="10797e767f50747f7d71797e3e737f7d">[email&nbsp;protected]</span></a> */}
                      <span>Online Support</span>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="media">
                    <div className="d-flex">
                      <img src="images/phone.png" alt="" />
                    </div>
                    <div className="media-body">
                      <p>906-624-2565</p>
                      <span>Mon-Fri 8am-5pm</span>
                    </div>
                  </div>
                </li>
              </ul>
              <div className="subscribe-from">
                <p>Get Subscribed!</p>
                <form
                  action="#"
                  method="POST"
                  id="subscribe-form"
                  className="subscribe-form"
                >
                  <label htmlFor="sub-input" />
                  <div className="form-group">
                    <input
                      type="email"
                      name="email"
                      id="sub-input"
                      placeholder="Enter your mail here"
                      className="form-control"
                    />
                    <button className="sub-btn" type="submit">
                      <i className="icon icon-arrow-right" />
                    </button>
                  </div>
                </form>
              </div>
              <ul className="social-list version-2">
                {/* <li><a href="#" class="facebook"><i class="fa fa-facebook"></i></a></li>
                     <li><a href="#" class="twitter"><i class="fa fa-twitter"></i></a></li>
                     <li><a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a></li>
                     <li><a href="#" class="instagram"><i class="fa fa-instagram"></i></a></li>
                     <li><a href="#" class="vimeo"><i class="fa fa-vimeo"></i></a></li> */}
                <li>
                  <a href="#" target="_blank" className="telegram">
                    <i className="fa fa-telegram" aria-hidden="true" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" className="twitter">
                    <i className="fa fa-twitter" />
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank" className="medium">
                    <img src="images/included-feature/medium.png" />
                  </a>
                </li>
                {/* <li><a href="https://t.me/FantomDomains" target="_blank" className="telegram"><i className="fa fa-telegram" aria-hidden="true" /></a></li>
                  <li><a href="https://twitter.com/fantomdomains" target="_blank" className="twitter"><i className="fa fa-twitter" /></a></li>
                  <li><a href="https://medium.com/@fantomdomains" target="_blank" className="medium"><img src="images/included-feature/medium.png" /></a></li> */}
              </ul>
              <div className="text-center">
                <a href="#" className="btn btn-primary">
                  Purchase Now
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="xs-bg-gray xs-section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div
                className="xs-heading wow fadeIn animated mb-5"
                style={{ visibility: "visible", animationName: "fadeIn" }}
              >
                <h2 className="heading-sub-title">Questions</h2>
                <h3 className="heading-title">
                  Frequently <span>Ask Question</span>
                </h3>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-6">
              <div id="accordion" className="xs-accordion" role="tablist">
                <div className="card isActive">
                  <div className="card-header" role="tab" id="headingOne">
                    <a
                      data-toggle="collapse"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      Why are names registered as hashes?{" "}
                      <i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse show"
                    role="tabpanel"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      <p>
                        By using hashes as a fixed length identifier, contracts
                        can handle these with a fixed overhead and will also be
                        able to pass strings with variable lengths.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingTwo">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How many transactions are needed to register a <br />
                      .ftm name? <i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTwo"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      The registrar needs two transactions, one commit and one
                      reveal, to register a name.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingThree">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      What do I need to do to ensure continued use of my FNS
                      name?
                      <i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingThree"
                    data-parent="#accordion"
                  >
                    <div className="card-body">
                      You need to ensure that you renew your FNS names before
                      expiration! You can add registration years to any name at
                      any time before expiration and for any duration.
                    </div>
                  </div>
                </div>
                {/* <div className="card">
                  <div className="card-header" role="tab" id="headingThree">
                    <a className="collapsed" data-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    What do I need to do to ensure continued use of my FNS name?<i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div id="collapseThree" className="collapse" role="tabpanel" aria-labelledby="headingThree" data-parent="#accordion">
                    <div className="card-body">
                    You need to ensure that you renew your FNS names before expiration! You can add registration years to any name at any time before expiration and for any duration.
                    </div>
                  </div>
                </div> */}
                {/*  <div class="card">
                        <div class="card-header" role="tab" id="headingFour">
                           <a class="collapsed" data-toggle="collapse" href="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                           How can I change the nameservers for my domain? <i class="icon icon-down-arrow1"></i>
                           </a>
                        </div>
                        <div id="collapseFour" class="collapse" role="tabpanel" aria-labelledby="headingFour" data-parent="#accordion">
                           <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod brunch
                           </div>
                        </div>
                     </div>
                     <div class="card">
                        <div class="card-header" role="tab" id="headingFive">
                           <a class="collapsed" data-toggle="collapse" href="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                           How do I generate a CSR code? <i class="icon icon-down-arrow1"></i>
                           </a>
                        </div>
                        <div id="collapseFive" class="collapse" role="tabpanel" aria-labelledby="headingFive" data-parent="#accordion">
                           <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin.
                           </div>
                        </div>
                     </div> */}
              </div>
            </div>
            <div className="col-lg-6">
              <div id="accordion-two" className="xs-accordion" role="tablist">
                <div className="card">
                  <div className="card-header" role="tab" id="headingSix">
                    <a
                      data-toggle="collapse"
                      className="collapsed"
                      href="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      Which wallets and dapps support FNS so far?{" "}
                      <i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div
                    id="collapseSix"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingSix"
                    data-parent="#accordion-two"
                  >
                    <div className="card-body">
                      <p>
                        We are working on integration with the following
                        wallets: Fantom Wallet (fWallet), Metamask, Coinbase
                        Wallet, Ledger, Trust Wallet
                        <br />
                        We are working on supporting the following apps:
                        FTMscan, SpookySwap, SpiritSwap.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingSeven">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      Is it possible to change the address my name resolves to
                      <br /> after my purchase?
                      <i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingSeven"
                    data-parent="#accordion-two"
                  >
                    <div className="card-body">
                      Yes, it is possible to change the address and other
                      resources pointed to your name at any point in time
                    </div>
                  </div>
                </div>
                {/*    <div class="card">
                        <div class="card-header" role="tab" id="headingEight">
                           <a class="collapsed" data-toggle="collapse" href="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                           How to transfer an existing hosting account? <i class="icon icon-down-arrow1"></i>
                           </a>
                        </div>
                        <div id="collapseEight" class="collapse" role="tabpanel" aria-labelledby="headingEight" data-parent="#accordion-two">
                           <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin.
                           </div>
                        </div>
                     </div>
                     <div class="card">
                        <div class="card-header" role="tab" id="headingNine">
                           <a class="collapsed" data-toggle="collapse" href="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                           How do I renew my domain? <i class="icon icon-down-arrow1"></i>
                           </a>
                        </div>
                        <div id="collapseNine" class="collapse" role="tabpanel" aria-labelledby="headingNine" data-parent="#accordion-two">
                           <div class="card-body">
                              Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod brunch
                           </div>
                        </div>
                     </div> */}
                <div className="card">
                  <div className="card-header" role="tab" id="headingTen">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      Can name owners create subsequent subdomains
                      <br /> as well? <i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div
                    id="collapseTen"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingTen"
                    data-parent="#accordion-two"
                  >
                    <div className="card-body">
                      Yes, name owners can create any subdomain they wish and
                      transfer ownership of them to others. It is possible to
                      set up your own registrar for your domain.
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" role="tab" id="headingEleven">
                    <a
                      className="collapsed"
                      data-toggle="collapse"
                      href="#collapseEleven"
                      aria-expanded="false"
                      aria-controls="collapseEleven"
                    >
                      How much will the yearly renewals cost?
                      <i className="icon icon-down-arrow1" />
                    </a>
                  </div>
                  <div
                    id="collapseEleven"
                    className="collapse"
                    role="tabpanel"
                    aria-labelledby="headingEleven"
                    data-parent="#accordion-two"
                  >
                    <div className="card-body">
                      Yearly renewals cost $10/year for names that are 5
                      characters or longer. 4 character names cost $120/year,
                      and 3 character names cost $480/year. Fees are paid in
                      FTM. The FTM/USD exchange rate is set by the Chainlink
                      FTM/USD oracle.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
