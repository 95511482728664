import React from 'react'

const Footer = () => {
  return (
    <div>
       <footer className="xs-footer-section">
        <div className="xs-feature-section">
          <div className="container">
            {/*  <div class="row feature-v2">
                  <div class="col-lg-6">
                     <div class="xs-feature-group wow fadeInLeft">
                        <div class="media">
                           <div class="feature-img">
                              <img src="assets/images/sheild-2.png" alt="hosting feature img">
                           </div>
                           <div class="media-body feature-content">
                              <h4 class="xs-title">Days money back guarantee</h4>
                              <p>Share processes an data secure lona need <br> to know basis without the need.</p>
                              <div class="xs-btn-wraper">
                                 <a href="#" class="btn btn-primary">Know More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="xs-feature-group wow fadeInRight">
                        <div class="media">
                           <div class="feature-img">
                              <img src="assets/images/cloud-lock-2.png" alt="hosting feature img">
                           </div>
                           <div class="media-body feature-content">
                              <h4 class="xs-title">Build and protect your brand</h4>
                              <p>Share processes an data secure lona need <br> to know basis without the need.</p>
                              <div class="xs-btn-wraper">
                                 <a href="#" class="btn btn-primary">Know More</a>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div> */}
          </div>
        </div>
        {/*  <div class="footer-group" style="background-image: url(assets/images/footer-bg.png);">
            <div class="footer-main">
               <div class="container">
                  <div class="row">
                     <div class="col-lg-3 col-md-6">
                        <div class="footer-widget wow fadeInUp">
                           <h3 class="widget-title">Services</h3>
                           <ul class="xs-list">
                              <li><a href="shared-hosting.html">Shared Hosting</a></li>
                              <li><a href="reseller-hosting.html">Reseller Hosting</a></li>
                              <li><a href="vps-hosting.html">VPS Hosting</a></li>
                              <li><a href="cloud-hosting.html">Cloud Hosting</a></li>
                              <li><a href="dedicated-hosting.html">Dedicated Hosting</a></li>
                              <li><a href="domain.html">Domain Name</a></li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-6">
                        <div class="footer-widget wow fadeInUp" data-wow-duration="1s">
                           <h3 class="widget-title">Company</h3>
                           <ul class="xs-list">
                              <li><a href="about.html">About us</a></li>
                              <li><a href="contact.html">Contact</a></li>
                              <li><a href="support.html">Support</a></li>
                              <li><a href="blog.html">Latest Blog</a></li>
                              <li><a href="pricing.html">Pricing</a></li>
                              <li><a href="affiliate.html">Affiliate</a></li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-6">
                        <div class="footer-widget wow fadeInUp" data-wow-duration="1.3s">
                           <h3 class="widget-title">Solutions</h3>
                           <ul class="xs-list">
                              <li><a href="who-is.html">Who Is</a></li>
                              <li><a href="terms.html">Terms of Service</a></li>
                              <li><a href="privacy.html">Privacy Policy</a></li>
                              <li><a href="faq.html">FAQ</a></li>
                              <li><a href="login.html">Log In</a></li>
                           </ul>
                        </div>
                     </div>
                     <div class="col-lg-3 col-md-6">
                        <div class="footer-widget wow fadeInUp" data-wow-duration="1.5s">
                           <h3 class="widget-title">Contact Info</h3>
                           <ul class="contact-info-widget">
                              <li class="media">
                                 <img src="assets/images/address-pin.png" class="d-flex" alt="contact icon">
                                 <span class="media-body">9/4c, 1010 Avenue, NY, USA</span>
                              </li>
                              <li class="media">
                                 <img src="assets/images/phone-pin.png" class="d-flex" alt="contact icon">
                                 <span class="media-body">009-215-5596 (toll free) 009-215-5596</span>
                              </li>
                              <li class="media">
                                 <img src="assets/images/email-icon.png" class="d-flex" alt="contact icon">
                                 <span class="media-body">9/4c, 1010 Avenue, NY, USA</span>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="container">
               <div class="footer-bottom">
                  <div class="row">
                     <div class="col-md-6">
                        <div class="footer-bottom-info wow fadeInUp">
                           <p>Offers valid for a limited time onlyand reflect multi annual discounts. Other terms and conditions may apply. <a href="#">Click Here</a></p>
                        </div>
                     </div>
                     <div class="col-md-6">
                        <ul class="xs-list list-inline wow fadeInUp" data-wow-duration="1s">
                           <li><img src="assets/images/security/security-company-images-1.png" alt="security company images"></li>
                           <li><img src="assets/images/security/security-company-images-2.png" alt="security company images"></li>
                           <li><img src="assets/images/security/security-company-images-3.png" alt="security company images"></li>
                           <li><img src="assets/images/security/security-company-images-4.png" alt="security company images"></li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div> */}
        <div className="footer-copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="copyright-text wow fadeInUp">
                  <p>© 2022 <a href="#">FantomDomains.io</a></p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer-logo-wraper wow fadeInUp" data-wow-duration="1s">
                  {/* <a href="index.html" class="footer-logo"><img class="footer_logo" src="assets/images/white-color-logo.png" alt="footer logo"></a> */}
                </div>
              </div>
              <div className="col-md-4">
                <div className="social-list-wraper wow fadeInUp" data-wow-duration="1.3s">
                  <ul className="social-list">
                
                    <li><a href="https://t.me/FantomDomains" target="_blank" className="telegram"><i className="fa fa-telegram" aria-hidden="true" /></a></li>
                    <li><a href="https://twitter.com/fantomdomains" target="_blank" className="twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="https://medium.com/@fantomdomains" target="_blank" className="medium"><img src="images/included-feature/medium.png" /></a></li> 
                    {/* <li><a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a></li>
                           <li><a href="#" class="instagram"><i class="fa fa-instagram"></i></a></li>
                           <li><a href="#" class="googlePlus"><i class="fa fa-google-plus"></i></a></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
